'use client'

import { createTheme, ThemeProvider } from '@mui/material'

import useThemeState from '@/hooks/context/useThemeState'

import { colors } from '@/branding-config'

interface MuiProviderProps {
  children: React.ReactNode
}

const MuiProvider: React.FC<MuiProviderProps> = ({ children }) => {
  const { theme } = useThemeState()

  const muiTheme = createTheme({
    palette: {
      mode: theme,
      primary: {
        main: colors[theme].primary,
      },
      secondary: {
        main: colors[theme].secondary,
      },
    },
  })

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
}

export default MuiProvider
