'use client'

import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs'
import { ConfigProvider, message, theme as antdTheme } from 'antd'
import { useServerInsertedHTML } from 'next/navigation'
import { PropsWithChildren } from 'react'

import useThemeState from '@/hooks/context/useThemeState'

import { colors } from '@/branding-config'

import { Theme } from '@/types/theme'

interface AntdProviderProps {
  fontFamily: string
  children: React.ReactNode
}

const AntdProvider: React.FC<AntdProviderProps> = ({
  fontFamily,
  children,
}) => {
  const { theme } = useThemeState()

  const cache = createCache()
  useServerInsertedHTML(() => (
    <style
      id='antd'
      dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}
    />
  ))

  const AntdConfig: React.FC<PropsWithChildren> = ({ children }) => (
    <ConfigProvider
      theme={{
        algorithm:
          theme === Theme.DARK
            ? antdTheme.darkAlgorithm
            : antdTheme.defaultAlgorithm,
        token: {
          fontFamily,
          colorPrimary: colors[theme].primary,
        },
      }}
    >
      <StyleProvider cache={cache}>{children}</StyleProvider>
    </ConfigProvider>
  )

  message.config({
    maxCount: 1,
  })

  return <AntdConfig>{children}</AntdConfig>
}

export default AntdProvider
