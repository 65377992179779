'use client'

import { getCookie } from 'cookies-next'
import { use } from 'i18next'
import { useEffect } from 'react'
import { initReactI18next } from 'react-i18next'

import { LANGUAGE } from '@/constants/env'
import { switchLanguage, translations } from '@/i18n'

interface I18nProviderProps {
  children: React.ReactNode
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  use(initReactI18next).init({
    resources: translations,
    lng: LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  })

  useEffect(() => {
    const lang = getCookie('locale')
    if (lang) {
      setTimeout(() => switchLanguage(lang))
    }
  }, [])

  return children
}

export default I18nProvider
