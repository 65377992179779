import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { GrantApplication } from '@/types/grants'

const useGrantApplication = (conversationId?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    conversationId ? ['grantApplication', conversationId] : null,
    async () => axios(`${API_URL}/grant-thread/${conversationId}`, config),
    { revalidateOnFocus: false }
  )

  if (error) {
    console.error(error)
  }

  return {
    grantApplication: data?.data.error
      ? undefined
      : (data?.data as GrantApplication),
    isLoading,
    error,
    mutate,
  }
}

export default useGrantApplication
