import { JSX, SVGProps } from 'react'

// https://www.svgrepo.com/svg/146474/doc
const DOC = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 303.188 303.188'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <polygon
          fill='#E8E8E8'
          points='219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	'
        />
        <g>
          <rect
            x='90.902'
            y='61.704'
            fill='#004A94'
            width='119.89'
            height='119.89'
          />
          <rect
            x='101.303'
            y='72.105'
            fill='#FFFFFF'
            width='99.089'
            height='99.087'
          />
          <g>
            <path
              fill='#004A94'
              d='M130.047,141.736l1.845-4.797l4.337-10.702l15.453-35.612h15.499v36.535
				c0,4.797-0.4,9.657-1.199,14.577c0.307-0.861,2.352-6.181,6.135-15.961l13.978-35.15h17.852l-29.292,67.441H153.39v-28.6
				c0-4.766,0.199-9.749,0.6-14.946c-1.016,4.09-2.83,9.456-5.443,16.099l-11.671,27.447h-20.021l-2.26-67.441h17.067l-0.461,33.813
				C131.016,132.649,130.631,138.415,130.047,141.736z'
            />
          </g>
        </g>
        <polygon
          fill='#004A94'
          points='227.64,25.263 32.842,25.263 32.842,0 219.821,0 	'
        />
        <g>
          <path
            fill='#A4A9AD'
            d='M123.511,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.605,6.498-18.591,6.498H82.784v-47.979
			H99.39c7.701,0,13.646,1.969,17.836,5.907C121.415,235.737,123.511,241.426,123.511,248.864z M110.056,249.324
			c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.938,0,6.826-1.143,8.663-3.43
			C109.136,257.523,110.056,254.028,110.056,249.324z'
          />
          <path
            fill='#A4A9AD'
            d='M177.003,249.816c0,8.073-1.98,14.21-5.939,18.411c-3.961,4.201-9.758,6.301-17.395,6.301
			c-7.525,0-13.297-2.111-17.311-6.333c-4.016-4.222-6.021-10.371-6.021-18.444c0-7.985,1.996-14.083,5.988-18.295
			c3.992-4.211,9.797-6.317,17.41-6.317c7.635,0,13.422,2.089,17.361,6.268C175.034,235.585,177.003,241.721,177.003,249.816z
			 M143.989,249.816c0,9.276,3.227,13.915,9.68,13.915c3.283,0,5.717-1.126,7.303-3.38c1.586-2.253,2.379-5.765,2.379-10.534
			c0-4.791-0.805-8.33-2.412-10.617c-1.607-2.286-4.01-3.429-7.203-3.429C147.237,235.77,143.989,240.452,143.989,249.816z'
          />
          <path
            fill='#A4A9AD'
            d='M207.031,235.835c-3.085,0-5.492,1.264-7.221,3.791c-1.728,2.527-2.592,6.011-2.592,10.452
			c0,9.233,3.511,13.849,10.534,13.849c2.122,0,4.179-0.295,6.17-0.886c1.99-0.591,3.992-1.302,6.005-2.133v10.961
			c-4.003,1.772-8.532,2.658-13.586,2.658c-7.242,0-12.794-2.1-16.655-6.301c-3.861-4.201-5.792-10.272-5.792-18.214
			c0-4.966,0.935-9.331,2.806-13.094s4.562-6.656,8.073-8.68c3.512-2.023,7.641-3.036,12.389-3.036
			c5.186,0,10.141,1.127,14.866,3.38l-3.971,10.206c-1.772-0.831-3.544-1.531-5.316-2.1
			C210.969,236.12,209.066,235.835,207.031,235.835z'
          />
        </g>
        <polygon
          fill='#D1D3D3'
          points='219.821,50.525 270.346,50.525 219.821,0 	'
        />
      </g>
    </svg>
  )
}

export default DOC
