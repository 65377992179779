import axios from 'axios'
import useSWR, { mutate as mutateSwr } from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Swarm } from '@/types'

const KEY_NAME = 'swarm'
const ALL_SWARMS = 99999

const useSwarm = (page?: number, limit?: number, search?: string) => {
  const config = {
    method: 'post',
    withCredentials: true,
    ...configHeader,
    data: JSON.stringify({
      page: page ?? 0,
      limit: limit ?? ALL_SWARMS,
      search: search !== '' ? search : undefined,
    }),
  }

  const { data, error, isLoading, mutate } = useSWR(
    [KEY_NAME, page, limit, search],
    async () => axios(`${API_URL}/swarm`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    swarms: error ? [] : (data?.data.swarms as Swarm[]),
    count: data?.data.totalCount,
    isLoading,
    error,
    mutate,
  }
}

export default useSwarm

export async function mutateAllSwarms() {
  await mutateSwr(
    (key: any) =>
      key instanceof Array && typeof key[0] === 'string' && key[0] === KEY_NAME
  )
}
