import { createContext } from 'react'

import { GrantApplicationProps } from './grant.types'

export const GrantApplicationContext = createContext<GrantApplicationProps>({
  questions: [],
  steps: {},
  currentStep: -1,
  isMatcherOpen: true,
  isUndoDisabled: true,
  isRedoDisabled: true,
  storeToDb: async () => {},
  setCurrentStep: () => {},
  setMode: () => {},
  setSections: () => {},
  setSteps: () => {},
  setQuestions: () => {},
  setIsMatcherOpen: () => {},
  handleUndo: () => {},
  handleRedo: () => {},
})
