import { useContext } from 'react'

import { ModalsContext } from '@/context/modals/modals.context'

const useModalsState = () => {
  const modalsContext = useContext(ModalsContext)
  if (!Object.keys(ModalsContext).length) {
    throw new Error('ModalsContext must be used within its provider')
  }
  return modalsContext
}

export default useModalsState
