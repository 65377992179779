import axios from 'axios'
import useSWR, { mutate as mutateSwr } from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Agent } from '@/types'

const KEY_NAME = 'agent'
const ALL_AGENTS = 99999

const useAgents = (page?: number, limit?: number, search?: string) => {
  const config = {
    method: 'post',
    withCredentials: true,
    ...configHeader,
    data: JSON.stringify({
      page: page ?? 0,
      limit: limit ?? ALL_AGENTS,
      search: search !== '' ? search : undefined,
    }),
  }

  const { data, error, isLoading, mutate } = useSWR(
    [KEY_NAME, page, limit, search],
    async () => axios(`${API_URL}/agent`, config),
    { revalidateOnFocus: false }
  )

  return {
    selectedAgentAdmin: data?.data.agents?.find(
      (agent: Agent) => agent.adminSelected
    ) as Agent,
    selectedAgent: data?.data.agents?.find(
      (agent: Agent) => agent.isSelected
    ) as Agent,
    agents: error ? [] : (data?.data.agents as Agent[]),
    count: data?.data.totalCount,
    isLoading,
    error,
    mutate,
  }
}

export default useAgents

export async function mutateAllAgents() {
  await mutateSwr(
    (key: any) =>
      key instanceof Array && typeof key[0] === 'string' && key[0] === KEY_NAME
  )
}
