'use client'

import { usePathname } from 'next/navigation'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import useModalsState from '@/hooks/context/useModalsState'

import { initialState, LoadingContext } from './loading.context'

export const LoadingProvider = memo(({ children }: PropsWithChildren) => {
  const [loadingState, setLoadingState] = useState(initialState)
  const pathname = usePathname()
  const { toggleModal } = useModalsState()
  const [interval] = useState<NodeJS.Timeout>()

  useEffect(() => {
    stopLoading()
    toggleModal('')
  }, [pathname])

  const startLoading = () => {
    const interval = setInterval(() => {
      setLoadingState(({ progress }) => {
        if (progress > 95) {
          clearInterval(interval)
          return { loading: true, progress }
        }
        return { loading: true, progress: progress + (100 - progress) / 50 }
      })
    }, 100)
  }

  const stopLoading = () => {
    setLoadingState({ loading: false, progress: 100 })
    setTimeout(() => setLoadingState({ loading: false, progress: 0 }), 1000)
    if (interval) {
      clearInterval(interval)
    }
  }

  const contextValue = useMemo(() => {
    return {
      ...loadingState,
      startLoading,
      stopLoading,
    }
  }, [loadingState])

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  )
})
