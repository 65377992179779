import { RiSearchLine } from '@remixicon/react'
import { Input } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDebounce } from '@/hooks/useDebounce'

import { cn } from '@/utils/clsx'

interface SearchFieldProps {
  handleUserInputChange: (value: string) => void
  className?: string
}

const SearchField: React.FC<SearchFieldProps> = ({
  handleUserInputChange,
  className,
}) => {
  const [input, setInput] = useState('')
  const debounceUserInput = useDebounce(handleUserInputChange)

  const { t } = useTranslation()
  const placeholder = t('search')

  return (
    <div className={cn('relative flex w-full items-center', className)}>
      <Input
        placeholder={placeholder}
        prefix={<RiSearchLine size={17} />}
        value={input}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInput(event.target.value)
          debounceUserInput(event.target.value)
        }}
      />
    </div>
  )
}

export default SearchField
