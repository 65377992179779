import { RiCloseLine, RiSave3Line } from '@remixicon/react'
import { Button, Col, Input, message, Modal, Row, Select } from 'antd'
import axios from 'axios'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'
import { mutateAllSwarms } from '@/hooks/useSwarms'

import InformationCircle from '@/components/Admin/Modals/FileHub/ImportBody/InformationCircle'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IAxiosError } from '@/types/api'

const { TextArea } = Input

const CreateSwarm = () => {
  const [name, setName] = useState<string | undefined>('')
  const [agents, setAgents] = useState<string[]>([])
  const [masterPrompt, setMasterPrompt] = useState<string | undefined>('')

  const { t } = useTranslation()
  const { openModal, toggleModal } = useModalsState()
  const { agents: allAgents } = useAgents()
  const router = useRouter()

  const saveSwarm = async () => {
    message.loading(t('toast-loading'))

    const config = {
      method: 'post',
      ...configHeader,
      data: JSON.stringify({
        name: name,
        agents: agents,
        masterPrompt: masterPrompt,
      }),
    }

    try {
      const res = await axios(`${API_URL}/swarm/insert`, config)

      message.success(t('swarm-created-success'))
      toggleModal('create-swarm')
      await mutateAllSwarms()
      router.push(`/admin/swarms/${res.data.swarm.id}`)
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      open={openModal === 'create-swarm'}
      onCancel={() => toggleModal('create-swarm')}
      title={t('swarm-create-new')}
      footer={null}
      width={600}
      className='rounded-lg border border-on-background/20 dark:border-dark-on-background/20'
    >
      <div className='flex flex-col gap-3 pt-4'>
        <div className='gap-3 sm:flex-row'>
          <Row>
            <Col span={8} className='flex items-center'>
              {t('swarm-name-title')}
            </Col>
            <Col span={16}>
              <Input
                value={name}
                placeholder={t('swarm-name')}
                onChange={(value) => setName(value.target.value)}
              />
            </Col>
          </Row>
          <Row className='pt-2'>
            <Col span={8} className='flex items-center'>
              {t('swarm-agents-title')}
            </Col>
            <Col span={16}>
              <Select
                className='w-full'
                mode='multiple'
                allowClear
                placeholder={t('swarm-agents-placeholder')}
                value={agents}
                onChange={(value) => setAgents(value)}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                options={allAgents?.map((a) => ({
                  label: a.name,
                  value: a.id,
                }))}
              />
            </Col>
          </Row>
          <Row className='pt-2'>
            <Col span={8}>
              <div className='flex min-w-max gap-1'>
                {t('swarm-master-prompt-title')}
                <InformationCircle text={t('swarm-master-prompt-desc')} />
              </div>
            </Col>
            <Col span={16}>
              <TextArea
                rows={4}
                value={masterPrompt}
                placeholder={t('swarm-master-prompt')}
                onChange={(value) => setMasterPrompt(value.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className='mt-4 flex justify-end gap-2 text-xs'>
        <Button
          className='flex items-center px-3'
          icon={<RiSave3Line className='size-5' />}
          onClick={saveSwarm}
          disabled={
            name === undefined ||
            name === '' ||
            name.length < 1 ||
            agents === undefined ||
            agents.length < 1
          }
        >
          {t('save')}
        </Button>
        <Button
          className='flex items-center px-3'
          icon={<RiCloseLine className='size-5' />}
          onClick={() => {
            toggleModal('create-swarm')
          }}
        >
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateSwarm
