'use client'
import { message } from 'antd'
import { memo, PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

import useAuth from '@/hooks/useAuth'

import { NEXT_PUBLIC_WEBSOCKET_URL } from '@/constants/env'

const WebsocketProvider = memo(({ children }: PropsWithChildren) => {
  const { user } = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    if (!user || !NEXT_PUBLIC_WEBSOCKET_URL) return

    const wsClient = new W3CWebSocket(
      `${NEXT_PUBLIC_WEBSOCKET_URL}?user=${user?.email}`
    )

    wsClient.onopen = () => {
      console.warn('WebSocket Client Connected')
    }

    wsClient.onerror = (error) => {
      console.error('Connection Error: ' + error.message)
    }

    wsClient.onclose = () => {
      console.warn('Client connection closed')
    }

    wsClient.onmessage = (msg) => {
      if (typeof msg.data === 'string') {
        switch (msg.data.replace(/['"]+/g, '')) {
          case 'success-adding-document':
            message.success(t('toast-import-success'))
            break
          case 'error-adding-document':
            message.error(t('toast-import-failure'))
            break
          default:
            console.warn('Received: ' + msg.data)
        }
      } else {
        console.warn(`Received Binary Message of ${msg.data.byteLength} bytes`)
      }
    }

    return () => {
      wsClient.close()
    }
  }, [user])

  return <>{children}</>
})

export default WebsocketProvider
