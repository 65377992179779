import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

type ApiResponse = {
  data: {
    ips: string[]
  }
}

const useIps = () => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(['ips'], async () =>
    axios(`${API_URL}/logs/ips`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    ips: convertAPIResponse(data),
    isLoading,
    error,
    mutate,
  }
}

const convertAPIResponse = (APIResponse?: ApiResponse) => {
  if (!APIResponse) {
    return []
  }
  return APIResponse?.data.ips
}

export default useIps
