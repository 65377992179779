import { RiInformationLine } from '@remixicon/react'
import { Tooltip } from 'antd'

interface IInformationCircleProps {
  text: string
  size?: number
}

const InformationCircle: React.FC<IInformationCircleProps> = ({ text }) => {
  return (
    <Tooltip placement='top' title={text}>
      <div>
        <RiInformationLine className='size-5 cursor-pointer opacity-60 hover:opacity-40' />
      </div>
    </Tooltip>
  )
}

export default InformationCircle
