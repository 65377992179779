'use client'

import { memo, PropsWithChildren, useMemo, useState } from 'react'

import { initialState, ModalsContext } from './modals.context'

const ModalsProvider = memo(({ children }: PropsWithChildren) => {
  const [openModal, setOpenModal] = useState(initialState.openModal)
  const [reexecuteQuery, setReexecuteFunctions] = useState(
    initialState.reexecuteQuery
  )

  const toggleModal = (modalName: string) => {
    if (modalName === openModal) {
      return setOpenModal('')
    }
    return setOpenModal(modalName)
  }

  const contextValue = useMemo(() => {
    return {
      openModal,
      toggleModal,
      reexecuteQuery,
      setReexecuteFunctions,
    }
  }, [openModal, reexecuteQuery])

  return (
    <ModalsContext.Provider value={contextValue}>
      {children}
    </ModalsContext.Provider>
  )
})

export default ModalsProvider
