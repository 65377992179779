import { Modal } from 'antd'

import useModalsState from '@/hooks/context/useModalsState'

import ImportBody from './FileHub/ImportBody'

const ImportUserDocumentModal: React.FC = () => {
  const { toggleModal, openModal } = useModalsState()

  return (
    <Modal
      centered
      open={openModal === 'import-user-document'}
      onCancel={() => toggleModal('import-user-document')}
      footer={null}
      width={600}
      className='rounded-lg border border-on-background/20 dark:border-dark-on-background/20'
    >
      <ImportBody importToUserStorage />
    </Modal>
  )
}

export default ImportUserDocumentModal
